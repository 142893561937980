import './App.css';
import Header from './components/Header';
import Meme from './components/Meme';
// import Meme2 from './components/Memee';

function App() {
  return (
    <div className="App">
      <div className="container">
        <Header />
        <Meme />
        {/* <Meme2 /> */}
      </div>
    </div>
  );
}

export default App;
